<!--
 * @Author: 墨凡 oncwnuN3VU3V55c1lqO_6llf5DtU@git.weixin.qq.com
 * @Date: 2022-08-19 16:37:18
 * @LastEditors: 墨凡 oncwnuN3VU3V55c1lqO_6llf5DtU@git.weixin.qq.com
 * @LastEditTime: 2022-08-23 16:58:14
 * @FilePath: \ding-lims\src\views\Statistics.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="Statistics">
    <van-nav-bar
      title="数据分析"
      left-text="返回"
      left-arrow
      @click-left="back" />
    <van-grid :gutter="10" :column-num="3" class="mt10">
      <van-grid-item v-for="(item, idx) in submenu" :key="idx" 
        icon-prefix="icon-font" 
        :icon="item.menu_icon" 
        :text="item.title"
        @click="toUrl(item.url)"
        replace />
    </van-grid>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import { Grid, GridItem, Cell, CellGroup, Icon } from 'vant'
// import '@/lib/icon/iconfont.css'
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Icon)

export default {
  name: 'Statistics',
  components: {
  },
  data() {
    return {
      user: { },
      submenu: [ ]
    }
  },
  // computed: {
  //   menuData() {
  //     console.log(this.menu)
  //     return this.menu
  //   }
  // },
  created() {
    this.asyncMenuData().then(res => {
      const list = res.filter(ref => ref.id === "188")
      this.submenu = list[0].child
    })
  },
  methods: {
    ...mapActions('router', ['asyncMenuData']),
    back() {
      this.$router.push({ path: '/home' })
    },
    toUrl(url) {
      this.$router.push({path:'/' + url})
    }
  }
}
</script>

<style>
.des{
  font-size: 12px;
  line-height: 1.628em;
  padding: 10px 16px;
}
</style>
